export function Index() {
  return (
    <div>
      
    </div>
  )
}

Index.authenticate = true
Index.redirectAuthenticatedTo = `/open-roles`


export default Index
